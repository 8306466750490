<template>
  <a-spin :spinning="globalLoding">
    <div class="mainPage">
      <p class="pageTitle">Tickets list</p>
      <div class="pageWrap">
        <a-tabs size="small" v-model="defaultActiveKey" @change="handleChangeTab">
          <a-tab-pane key="all" tab="All"></a-tab-pane>
          <template v-for="(item, index) in ticketStatusMapper.slice(1)">
            <a-tab-pane :key="index + 1" :tab="item" v-if="index !== 2 && index !== 3"></a-tab-pane>
          </template>
        </a-tabs>
        <commonSearch class="mt16" :dataStructure="structure" :ticketType="ticketType" :searchInfo="params" @search="getTicketList(1)" @export="handleExport" @clear="resetParams" />
        <a-table
          :loading="tableLoading"
          :data-source="ticketList"
          :pagination="false"
          class="mt24">
          <a-table-column key="ticketNo" title="Tickets ID" data-index="ticketNo" />
          <a-table-column key="shippingCountry" title="Country" data-index="shippingCountry" />
          <a-table-column key="modelId" title="model ID" data-index="modelId" />
          <a-table-column key="B/N number" title="B/N number">
            <template slot-scope="row">
              {{ row.bnCodeLine1 }} <br> {{ row.bnCodeLine2 }}
            </template>
          </a-table-column>
          <a-table-column key="skuId" title="sku ID" data-index="skuId" />
          <a-table-column key="purchaseOrderNo" title="Order Number" data-index="purchaseOrderNo" />
          <a-table-column key="treatment" title="Treatment" data-index="treatment">
            <template slot-scope="treatment">
              {{ treatment ? 'repair' : 'replace' }}
            </template>
          </a-table-column>
          <a-table-column key="uploadLater" title="Product image">
            <template slot-scope="row">
              {{ (row.submitProductImageLater === 1 || (!row.destroyImageUrl && row.status === 3)) ? 'Incomplete' : 'Completed'}}
            </template>
          </a-table-column>
          <a-table-column key="lastOperatorAccount" title="Last Operator" data-index="lastOperatorAccount">
            <template slot-scope="lastOperatorAccount">
              {{lastOperatorAccount || '-'}}
            </template>
          </a-table-column>
          <a-table-column key="lastUpdatedTime" title="Last updated" data-index="lastUpdatedTime">
            <template slot-scope="lastEditTime">
              {{ timeFormatUSA(lastEditTime) || '-' }}
            </template>
          </a-table-column>
          <a-table-column key="isRepeatBnCodeLine" title="B/N repeat" data-index="isRepeatBnCodeLine">
            <template slot-scope="isRepeatBnCodeLine">
              {{ isRepeatBnCodeLine ? 'Yes' : 'No' }}
            </template>
          </a-table-column>
          <a-table-column key="logisticsNo" title="Logistics No" data-index="logisticsNo">
            <template slot-scope="logisticsNo">
              {{logisticsNo || '-'}}
            </template>
          </a-table-column>
          <a-table-column key="submissionTime" title="Submission time" data-index="submissionTime">
            <template slot-scope="timeStamp">
              {{ timeFormatUSA(timeStamp, 'YYYY-MM-DD HH:mm:ss', 'America/New_York') }}
            </template>
          </a-table-column>
          <a-table-column key="status" title="Ticket state" data-index="status">
            <template slot-scope="status">
              {{ ticketStatusMapper[status] }}
            </template>
          </a-table-column>
          <a-table-column key="Operation" title="Operation">
            <template slot-scope="row">
              <a v-if="row.status === 3 || (row.status === 2 && row.submitProductImageLater === 1)" @click="$router.push(`/ticket/detail/${row.id}`)">Edit</a>
              <a v-else-if="row.status === 2 || row.status === 4" @click="$router.push(`/ticket/detail/${row.id}`)">review</a>
              <a v-else @click="$router.push(`/ticket/detail/${row.id}`)">detail</a>
            </template>
          </a-table-column>
        </a-table>
        <a-pagination
          v-model="page.currentPage"
          :total="page.total"
          :page-size.sync="page.pageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          @change="getTicketList()"
          @showSizeChange="getTicketList()"
          class="mt16">
        </a-pagination>
      </div>
    </div>
  </a-spin>
</template>

<script>
import commonSearch from '@/components/commonSearch.vue'
import { structure, ticketStatusMapper } from './var'
import { getUSAtime, timeFormatUSA } from '@/utils/utils.js'
import btnIsPermissions from '../../utils/btnPermissions'

export default {
  name: 'TicketList',
  components: { commonSearch },
  props: {
    ticketType: [Number, String]
  },
  data () {
    return {
      globalLoding: false,
      exportTimer: null,
      structure,
      ticketStatusMapper,
      defaultActiveKey: 'all',
      tableLoading: false,
      params: {
        modelId: 'all',
        ticketNo: '',
        dateRange: [],
        userEmail: '',
        shippingPhone: '',
        shippingCountry: 'all',
        bnNumber: '',
        ticketId: '',
        shipping: 'all',
        shippingAddressee: '',
        shippingOrderNo: '',
        operatorAccount: '',
        submitProductImageLater: 'all',
        isRepeatBnCodeLine: 'all'
      },
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      pageSizeOptions: ['5','10', '20', '50'],
      ticketList: []
    }
  },
  // created () {
  //   this.defaultActiveKey = this.$route.query.type ? Number(this.$route.query.type) : 'all'
  // },
  mounted () {
    this.getTicketList()
  },
  methods: {
    async handleExport (exportDetail) { // type 导出  明细导出 等后端确认字段
      const permissionName = exportDetail ? 'exportTicketDetail' : 'exportTicket'
      const isPermision = await btnIsPermissions(permissionName)
      if (!isPermision) return
      
      let data = {}
      Object.keys(this.params).forEach(item => {
        if (item === 'dateRange' && this.params.dateRange.length) {
          data.startTime = getUSAtime(this.params.dateRange[0]  + ' 00:00:00', 'America/New_York')
          data.endTime = getUSAtime(this.params.dateRange[1] + ' 23:59:59', 'America/New_York')
        } else {
          const strValid = typeof this.params[item] === 'string' ? this.params[item].trim() : true
          const illValid = (this.params[item] !== null) && (this.params[item] !== undefined) && (this.params[item] !== 'all')
          data[item] = strValid && illValid ? this.params[item] : undefined
        }
      })
      data.dateRange = undefined
      this.$http.post('/platform/admin/ticketAdminManage/v1/exportTicket', {
        ticketStatus: this.defaultActiveKey === 'all' ? null : this.defaultActiveKey,
        type: this.ticketType,
        exportDetail,
        ...data
      }).then(res => { 
        if (res.code === 0) {
          this.handleRotation(res.data.id)
        }
      })
    },
    handleRotation (id) {
      this.globalLoding = true
      this.exportTimer = setInterval(async () => {
        try {
          const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/getDownloadTask', { id })
          if (res.data?.status === 2) {
            location.href = res.data.accessUrl
            this.handleClearTimer()
          } else if (res.data?.status === 1) { 
            this.handleClearTimer() // 测试看看需不需在此处弹出提示失败信息
          }
        } catch (error) {
          this.handleClearTimer()
        }
      }, 1000)
    },
    handleClearTimer () {
      this.globalLoding = false
      clearInterval(this.exportTimer)
    },
    timeFormatUSA,
    resetParams () {
      this.params = { ...this.$options.data().params }
    },
    getTicketList (page) {
      this.tableLoading = true
      let data = {}
      Object.keys(this.params).forEach(item => {
        if (item === 'dateRange' && this.params.dateRange.length) {

          data.startTime = getUSAtime(this.params.dateRange[0] + ' 00:00:00', 'America/New_York')
          data.endTime = getUSAtime(this.params.dateRange[1] + ' 23:59:59', 'America/New_York')
        } else {
          const strValid = typeof this.params[item] === 'string' ? this.params[item].trim() : true
          const illValid = (this.params[item] !== null) && (this.params[item] !== undefined) && (this.params[item] !== 'all')
          data[item] = strValid && illValid ? this.params[item] : undefined
        }
      })
      data.dateRange = undefined

      const booleanVal = [false, true]

      data.isRepeatBnCodeLine = booleanVal[data.isRepeatBnCodeLine]
      
      this.$http.post('/platform/admin/ticketAdminManage/v1/pageTicket', {
        ...data,
        ticketStatus: this.defaultActiveKey === 'all' ? null : this.defaultActiveKey,
        currentPage: page || this.page.currentPage,
        pageSize: this.page.pageSize,
        type: this.ticketType
      }).then(res => {
        this.ticketList = res.data.ticketList
        this.$set(this.page, 'total', res.data.totalRecord)
        if (page === 1) {
          this.$set(this.page, 'currentPage', page)
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    handleChangeTab (key) {
      // const ticketType = this.$route.query.ticketType || this.ticketType
      // if (key !== 'all') {
      //   this.$router.push({
      //     path: '/ticket/list',
      //     query: { type: key, ticketType }
      //   })
      // } else {
      //   this.$router.push({
      //     path: '/ticket/list',
      //     query: { ticketType }
      //   })
      // }
      this.resetParams()
      this.getTicketList(1)
    }
  },
  destroyed () { 
    this.handleClearTimer()
  }
}
</script>

<style>

</style>