<template>
  <div class="common-search-wrap">
    <a-form-model :model="value" :rules="dataStructure.rules" ref="ruleForm" layout="inline">
      <a-form-model-item
        class="item"
        :prop="item.variableName"
        :label="item.label"
        :class="['common-search-item', item.variableName, {'br': (item.type === 'br')}]"
        v-for="(item, index) in dataStructure.data"
        :key="index"
      >
        <!-- 下拉菜单 -->
        <a-select
          v-model="value[item.variableName]"
          v-if="item.type === 'select'"
        >
          <a-select-option
            v-for="option in item.options"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </a-select-option>
        </a-select>
        <!-- 单选框 -->
        <a-radio-group
          v-if="item.type === 'radio-group'"
          v-model="value[item.variableName]"
        >
          <a-radio-button
            v-for="option in item.options"
            :key="option.value"
            :label="option.value"
          >{{ option.label }}</a-radio-button>
        </a-radio-group>
        <!-- 输入框 -->
        <a-input
          v-model.trim="value[item.variableName]"
          v-if="item.type === 'input'"
          :type="item.inputType || 'text'"
        ></a-input>
        <!-- 时间选择器 -->
        <a-range-picker
          v-if="item.type === 'time'"
          v-model="value[item.variableName]"
          valueFormat="YYYY-MM-DD"
          type="datetimerange"
          :allowClear="typeof item.allowClear === 'boolean' ? item.allowClear : true"
        >
        </a-range-picker>
        <!-- 开关 -->
        <a-switch
          v-if="item.type === 'switch'"
          v-model="value[item.variableName]"
          active-color="#409EFF"
          inactive-color="#666666">
        </a-switch>

      </a-form-model-item>

      <a-form-model-item>
        <a-input-group compact class="compact-style">
          <a-select v-model="selKey" @change="handleChangeKey">
            <a-select-option value="shippingAddressee">
              Customer Name
            </a-select-option>
            <a-select-option value="purchaseOrderNo">
              order number
            </a-select-option>
          </a-select>
          <a-input style="width: 180px" v-model="value[selKey]"/>
        </a-input-group>
      </a-form-model-item>

      <!-- 搜索按钮 -->
      <a-button v-if="dataStructure.searchBtn" class="item mr8" type="primary" @click="search('ruleForm')">{{
        dataStructure.searchBtn
      }}</a-button>
      <a-button v-if="dataStructure.clearBtn" class="item mr8" type="primary" @click="$emit('clear')">{{
        dataStructure.clearBtn
      }}</a-button>
      <a-button class="item mr8" @click="$emit('export')">Export</a-button>
      <a-button class="item" @click="$emit('export', true)" v-if="Number(ticketType) !== 1">Detailed export</a-button>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'CommonSearch',
  props: {
    dataStructure: {
      type: Object,
      required: true,
      default: {
        trimEmptyValue: true
      },
    },
    searchInfo: {
      type: Object
    },
    ticketType: [Number, String]
  },
  watch: {
    value: {
      deep: true,
      immediate: false,
      handler(val) {
        this.$emit('update:searchInfo', val)
      }
    },
    searchInfo: {
      deep: true,
      immediate: true,
      handler (val) {
        this.value = this.searchInfo
      }
    }
  },
  data () {
    return {
      value: {},
      selKey: 'shippingAddressee'
    }
  },
  mounted () {
    // if (this.searchInfo) {
    //   this.value = this.searchInfo
    // }
  },
  methods: {
    search (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('search', this._trimEmptyString(this.value))
        } else {
          return false;
        }
      })
    },
    /**
     * trim 掉空字符串和null的值
     */
    _trimEmptyString (oldObj) {
      let newObj = {}
      Object.keys(oldObj).forEach(item => {
        if (typeof(oldObj[item]) === 'string' ) {
          newObj[item] = oldObj[item].trim()
        } else {
          newObj[item] = oldObj[item]
        }
      })
      return newObj
    },
    handleChangeKey (val) { 
      const delName = val === 'purchaseOrderNo' ? 'shippingAddressee' : 'purchaseOrderNo'
      this.value[delName] = ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.common-search-wrap {
  margin-bottom: 16px;
  border-radius: 2px;
  .common-search-item {
    &.br {
      display: block;
    }
    /deep/ .ant-col.ant-form-item-control-wrapper {
      min-width: 166px;
    }
    .ant-select {
      .ant-select-selection {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}

.compact-style {
  /deep/ .ant-select-selection--single{
    width: 150px !important;
  }
}
</style>
